//
//
// user.scss
//
// Place your own sparrow CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// Toujours vérifier dans la partie scss/sparrow, qu'une fonction/class/variable n'existe pas pour faire ce qu'on veut faire.
// Si c'est le case redéfinir la variable dans _user_variables.scss


//@font-face HERE

@font-face {
    font-family: "MARIONTheessentials";
    src: url("../fonts/MARIONTheessentials.eot");
    src: url("../fonts/MARIONTheessentials.woff") format("woff"),
    url("../fonts/MARIONTheessentials.otf") format("opentype"),
    url("../fonts/MARIONTheessentials.svg#filename") format("svg");
}
.font-base {
  font-family: 'Jost', sans-serif;
}
.font-secondary {
  font-family: 'Italiana', serif;
}
.font-time {
  font-family: 'Rozha One', serif;
}
body {
  //--logo-color: $primary; NE MARCHE PAS
  --logo-color: #{$primary};
  --flower-color: #{$primary};
  --footer-color: #{$white};
  --guillemet-color: #{$primary};
  letter-spacing: 0.2rem;
}

footer {
  --logo-color: #{$secondary};
  --flower-color: #{$secondary};
}

/// SVGS UTILITIES
.fill-primary {
  fill: $primary !important;
}

.stroke-primary {
  stroke: $primary !important;
}

.text-shadow {
  text-shadow: 0 0 3px rgba(0,0,0,0.5);
}

#langswitcher {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

// FOR H2 class p
.p {
  font-size: $font-size-base !important;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $black;
}

// Selected text background-color
// ::selection {
//   background-color: $dark-blue;
// }

// #remise {
//   top: 87px;
//   z-index: 1021;
// }
// #remise .btn-primary:hover{
//   background-color: $primary !important;
//   border-color: $primary !important;
// }
// #remise .btn::before {
//   content: " ";
//   background-color: $primary;
//   height: 41.17px;
//   width: 82.39px;
//   border-radius: 0 0 82.39px 82.39px;
//   position: fixed;
//   top: 123px;
//   right: 0;
// }
// #remise .font-size-1 {
//   font-size: 0.6rem;
// }
// #remise .font-size-2 {
//   font-size: 0.9rem;
// }
.border-2 {
  border-width: 2px !important;
}
.border-gray {
  border-color: $gray !important;
}
.font-size-3 {
  font-size: 2.2rem;
  line-height: 0;
}
.absolute-bottom-centered {
    position: absolute;
    bottom: 0;  
    left: 50%;
    transform: translate(-50%, -30%);
}
.absolute-bottom-right {
    position: absolute;
    bottom: 0;  
    right: 0; 
}
.absolute-top-right {
    position: absolute;
    top: 0;  
    right: 0;
    transform: translate(0, -20%);
}
#soins::before {
  content: " ";
  background-image: url('../img/icons/fleur.png');
  background-size: cover;
  background-position: center center;
  height: 103px;
  width: 150px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.translate-top {
  transform: translate(0, -90%);
}
.mt-12 {
  margin-top: 20rem;
}
#block-afterwork::before {
  content: " ";
  background-color: $danger;
  height: 90px;
  width: 180px;
  border-radius: 180px 180px 0 0;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, -140%);
}
.z-index-1 {
  z-index: 1;
}
.mt-perfect {
  margin-top: 7rem;
}
.border-none {
  border: none;
}
.border-2 {
  border-width: 7px !important;
}
.hoverbox:hover .hover-d-none {
  display: none;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
.min-h-80 {
  min-height: 80% !important; 
}
.bg-light-beige {
  background-color: $lightbeige; 
}
@media (min-width: 768px){
  .translate-top {
    transform: translate(0, -90%);
  }
  .display-1 {
   font-size: 3.5rem;
  }
  .absolute-md-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
}
@media (min-width: 1025px){
  .display-1 {
   font-size: 6.5rem;
  }
}
@media (min-width: 2020px){
  .display-1 {
   font-size: 7.99rem;
  }
}
#contactAddress.secure:before {
    content: attr(data-domaine) "\0040" attr(data-nom);
    unicode-bidi: bidi-override;
    direction: rtl;
}
#contactTel.secure-tel:before {
    content: attr(data-tel);
    unicode-bidi: bidi-override;
    direction: rtl;
}
.menu > h5 {
  font-size: 1rem;
  color: #194656 !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important;
}
.title-primary > h1, .title-primary > h2, .title-primary > h3, .title-primary > h4, .title-primary > h5 {
  font-family: 'Jost';
  color: $primary;
  font-weight: 700;
}
.bg-holder-h-90 {
  min-height: 90% !important;
}