//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

//FONTS
@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap');
$font-family-base: 'Jost', sans-serif;
$headings-font-family: "MARIONTheessentials", serif;
$headings-font-weight: 400;
$font-size-base: 1.2rem;
$display1-weight:             300 !important;
$display2-weight:             300 !important;
$display3-weight:             300 !important;
$display4-weight:             300 !important;
$display1-size: 2rem;

//COLORS
// Définir une variable sémantique puis affecter aux couleurs génériques
$greenblue: #194656;
$primary: $greenblue;
$gray: #6D8A93;
$beige: #F9EFE5;
$ocre: #BC7258;
$dark: #0e262d;
$lightbeige: #FFFAF8;
$secondary: $beige;
$danger: $ocre;
$fancynav-collapse-bg: $primary;
$fancynavBarBackgroundColor: $primary;

//BTN
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-font-family: 'Rozha One' !important;
$btn-font-size: 1.2rem !important;
$btn-font-weight: 400 !important;